import { FloraProvider } from "@grupoboticario/flora-react";
import { CreateUser } from "./components/create-user/CreateUser";
import { gbInstitucionalTheme } from "@grupoboticario/flora";


export default function Root() {
  return (
    <FloraProvider theme={gbInstitucionalTheme}>
      <CreateUser />
    </FloraProvider>
  );;
}
