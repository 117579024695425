import { Box, Button, Container, Dropdown, DropdownItem, Flex, Grid, Heading, Input, Toaster } from '@grupoboticario/flora-react';
import { ChangeEvent, Fragment, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
// eslint-disable-next-line import/no-unresolved
// @ts-ignore
import { authService } from '@vdi/auth-service';

const groups = [
  { value: 'f8fe45b9-8680-4270-b8c3-689610bd8953', label: 'Pré Ativação - Atendentes DBM' },
  { value: 'ddf72b74-5939-4988-a268-89f35610a229', label: 'Pré Ativação - Atendentes ELO' },
  { value: '76e0b696-45ca-414c-b398-f2258822ad17', label: 'Pré Ativação - Supervisores DBM' },
  { value: '4a172463-34a3-4de0-9d4f-8311328a9c6e', label: 'Pré Ativação - Líderes DBM' },
  { value: '4ffdd6b0-3176-4d4b-993e-a41fa7fcf042', label: 'Pré Ativação - Supervisores ELO' },
  { value: '4e3e014d-d1aa-46e2-b2fa-d11e2a8fbade', label: 'Pré Ativação - Líderes ELO' },
  { value: '95c1cdbc-4534-4db0-98d7-54fe9aba2ce8', label: 'Pré Ativação - Administradores' },
  { value: '8dcc0680-da0f-4bb8-ab4a-205599b36684', label: 'Pré Ativação - Control DBM' },
  { value: 'a776ce13-28b7-434c-9ae6-5b8a7590ac0a', label: 'Pré Ativação - Mailing 1' },
  { value: 'fd7c8bbc-8544-4ff7-8477-6185ff286d34', label: 'Pré Ativação - Mailing 2' },
];

type FieldNameType = 'name' | 'geraId' | 'email';

const userItemFields: { label: string; name: FieldNameType }[] = [
  {
    label: 'Nome',
    name: 'name',
  },
  {
    label: 'Gera ID',
    name: 'geraId',
  },
  {
    label: 'Email',
    name: 'email',
  },
];

interface FormPayload {
  groups: string;
  users: Record<FieldNameType, string>[];
}

const onValidationError = () => {
  Toaster.notify({
    kind: 'error',
    description: 'Erro durante a validação do formulário. Verifique se todos os campos foram preenchidos',
    origin: 'right-top',
  });
};

export const CreateUser = (): JSX.Element => {
  const { control, register, handleSubmit, reset } = useForm<FormPayload>({
    defaultValues: {
      groups: groups[0].value,
      users: [{ name: '', geraId: '', email: '' }],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'users',
  });
  const [isSubmitting, setIsSubmitting] = useState(false)

  const onFormSubmit = async (formData: FormPayload) => {
    setIsSubmitting(true)
    try {
      const payloads = formData.users.map(user => ({
        ...user,
        groups: [formData.groups],
      }));

      const { token } = await authService.getTokenOrRedirect();

      const requests = payloads.map(payload =>
        fetch('https://vdi-core-users.venda-direta.grupoboticario.digital/users', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        }),
      );

      const responses = await Promise.allSettled(requests);

      responses.forEach((response, idx) => {
        if (response.status === 'fulfilled' && response.value.status >= 200 && response.value.status < 300) {
          return Toaster.notify({
            kind: 'success', description: `Usuário ${payloads[idx].email} criado com sucesso`, origin: 'right-top'
          })
        }

        return Toaster.notify({
          kind: 'error', description: `Erro ao cadastrrar usuário ${payloads[idx].email}`, origin: 'right-top', duration: 60000
        })
      })

      reset()

      return responses
    } catch (err) {
      Toaster.notify({ kind: 'error', description: 'Erro durante a criação dos usuários', origin: 'right-top' });
    } finally {
      setIsSubmitting(false)
    }
  };

  return (
    <Container>
      <Heading level={3} css={{ marginBottom: '$2' }}>Criar usuários</Heading>
      <Controller
        name="groups"
        control={control}
        render={({ field, fieldState }) => (
          <Dropdown
            {...field}
            id="groups"
            labelText="Grupos"
            invalid={!!fieldState.error}
            onChange={(evt: ChangeEvent<HTMLInputElement>) => {
              field.onChange(evt.target.value);
            }}
          >
            {groups.map(group => (
              <DropdownItem key={`groups__${group.value}`} value={group.value}>
                {group.label}
              </DropdownItem>
            ))}
          </Dropdown>
        )}
      />
      <Grid templateColumns="1fr 1fr 1fr auto" gap="$2">
        {fields.map((field, idx) => (
          <Fragment key={`field__${field.id}`}>
            {userItemFields.map(uif => (
              <Box key={`${field.id}__${uif.name}`}>
                <Input
                  id={`${field.id}__${uif.name}`}
                  label={uif.label}
                  {...register(`users.${idx}.${uif.name}`, { required: true })}
                />
              </Box>
            ))}
            <Flex justify="center" align="center">
              <Button type="button" hierarchy="tertiary" onClick={() => remove(idx)}>
                Remover
              </Button>
            </Flex>
          </Fragment>
        ))}
      </Grid>

      <Flex justify="space-between" css={{ marginTop: '$4' }}>
        <Button type="button" onClick={handleSubmit(onFormSubmit, onValidationError)} disabled={isSubmitting} isLoading={isSubmitting}>
          Salvar
        </Button>
        <Button type="button" hierarchy="secondary" onClick={() => append({ email: '', geraId: '', name: '' })}>
          Incluir outro usuário na lista
        </Button>
      </Flex>
    </Container >
  );
};
